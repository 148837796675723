import React, {useState} from "react";
import { LocalizationContext } from "../LocalizationContext";
import {Locales} from "../types";

interface Props {
    children: React.ReactNode
}

export const LocalizationContextProviderLayout: React.FC<Props> = (props) => {

    const [locale, setLocale] = useState<Locales>(() => {

        if (process.env.GATSBY_LOCALE) {
            return process.env.GATSBY_LOCALE as Locales
        }

        return 'ru-RU'
    })

    return (
        <LocalizationContext.Provider value={{locale, setLocale}}>
            {props.children}
        </LocalizationContext.Provider>
    )

}