import {useContext} from "react";
import {LocalizationContext} from "./LocalizationContext";
import {LocalizationContextType} from "./types";

export const useLocalizationContext = (): LocalizationContextType => {

    const localizationContext = useContext(LocalizationContext)

    if (localizationContext === null) {
        throw 'error during initialization localization'
    }

    return localizationContext
}