import React from "react"
import { getCookiesName } from './src/utils'
import { sendCloudNameToBot, setCookieFirstTime } from './src/apiRequests/utils'
import { convertUrlParamsToObject } from './src/utils'
import config from './src/config'

import {LocalizationContextProviderLayout} from "./src/localization/LocalizationContextProviderLayout"
import {TmpLocaleSwitcher} from "./src/components/_V2/TmpLocaleSwitcher"

import './src/styles/global.styl'
import './src/styles/_V2/global.scss'



//https://www.gatsbyjs.com/docs/browser-apis/#onClientEntry
export function onClientEntry() {

  // При заходе на страницу удаляем старые utm-метки:
  localStorage.removeItem(config.UTM_MARKS)

  //Проверка на наличие utm-параметров:
  // eslint-disable-next-line no-restricted-globals
  if (window.location.search) {

    const params = convertUrlParamsToObject(window.location.search)
    const store = {}

    for (let key of Object.keys(params)) {

      if (key.slice(0, 3) === 'utm') {

        const newKey = key.slice(0, 3) + key.charAt(4).toLocaleUpperCase() + key.slice(5)

        store[newKey] = params[key]
      }
    }

    if (Object.keys(store).length !== 0) {
      localStorage.setItem(config.UTM_MARKS, JSON.stringify(store))
    }
  }

  if (!localStorage.getItem(config.REFERRER)) {
    localStorage.setItem(config.REFERRER, document.referrer)
  }

  if (!localStorage.getItem(config.FIRST_PAGE_PATH)) {
    localStorage.setItem(config.FIRST_PAGE_PATH, document.location.href)
  }

  //Проверка cookies "firstTime,ha?"
  //(кука "firstTime,ha?" нужна, чтобы определить, первый раз пользователь на сайте или нет):
  if (!getCookiesName('firstTime,ha?')) {
    setCookieFirstTime('yeah')
  }
}

export function onInitialClientRender() {
  // Получаем параметр referrer из URL
  const urlParams = new URLSearchParams(location.search)
  const referrer = urlParams.get('referrer')

  // Отправляем referrer на бекенд
  if (referrer) {
    sendCloudNameToBot(referrer)
  }
}

export const wrapRootElement = ({ element }) => (
    <LocalizationContextProviderLayout>
        {process.env.NODE_ENV === 'development' && (
            <TmpLocaleSwitcher />
        )}

        {element}
    </LocalizationContextProviderLayout>
)
