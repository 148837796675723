module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e90c4473594249cfbc0f727aeab780df@o146885.ingest.sentry.io/5796714","environment":"production"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KTFGHT8","includeInDevelopment":false,"timeout":6500},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quick Resto","short_name":"Quick Resto","start_url":"/","display":"standalone","icon":"src/assets/images/favicon.png","lang":"ru","orientation":"portrait","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13873ecb4128b950be386eab19b5ddce"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
