export default {
  ADVERTISING_QR_SOURCE: 'advertising',

  // коды потребителей для амо
  CONSUMER_CODES: {
    MAIN_SITE: 'MAIN_SITE', // основной
    KZ2: 'KZ2', // для номеров +77
    BY: 'BY', // для номеров +375
  },

  // id воронки "Интеграторы" в AmoCRM
  STATUS_ID_PARTNERS: 32572534,

  // id менеджера в AmoCRM, ответственного за воронку "Интеграторы"
  RESPONSIBLE_USER_ID_PARTNERS: 3878944,

  // название поля в localStorage для utm-меток
  UTM_MARKS: 'utm_marks',

  // название поля в localStorage для источника (чапорт)
  WSC_REFERRER: 'wsc_referrer',

  // название поля в localStorage для посещенных страниц (чапорт)
  WSC_PAGES: 'wsc_pages',

  // название поля в localStorage для источника
  REFERRER: 'referrer',

  // название поля в localStorage для посещенных страниц
  FIRST_PAGE_PATH: 'firstPagePath',

  // id адресной книги "Подписка на акции" в сервисе SendPulse
  ACTION_SUBSCRIPTION_FIELD_ID: 226633,

  // id воронки "Продажи 2.0" в AmoCRM
  STATUS_ID_SALES20: 2044258,

  // SEND PULSE Config
  SEND_PULSE_BOOK_ID: '226594',
  SEND_PULSE_CLIENT_ID: '32002ddd6b034c1aee780370910f4a50',
  SEND_PULSE_CLIENT_SECRET: '96a599648efa3c574cecdd1a54fd4bf1',

  GOOGLE_RECAPTCHA_ID: '6Ld92j8pAAAAAMOsJ8ecXFYh8oEuuat1_YLNUJCY',
  GOOGLE_RECAPTCHA_ID_V2: '6Lc8_W8pAAAAALdsc_smkpRmZF_cijvoKwPUdPLA',

  YANDEX_API_KEY: '7dc1de1a-70f6-44b2-9452-0939115a4443',
  YANDEX_CAPTCHA_SITEKEY: 'ysc1_cJfBaP3QG1Glh5ja89OJsHodApU8B0nPKMTxdwkye14b4b0c',
}
