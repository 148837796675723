// Класс серверной ошибки
import { preparePhoneNumberForServerRequest } from '../utils'

export class NetError extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'NetError'
    Error.captureStackTrace(this, NetError)
  }
}

export function checkResponseStatus(response) {
  if (response.status !== 200) {
    throw new NetError('Http status error')
  }
  return response
}

/**
 * Более корретный на мой взгляд способ определения серверной ошибки
 */
export function detectNetError(rawResponse) {
  // В ряде случаев сервер шлет 502 или другие ошибки не в формате `json`.
  // Они подходят под критерий `netError`, и определяются по падению преобразования
  return (rawResponse) => rawResponse
    .json()
    .catch((error) => {
      throw new NetError(error.message)
    })
    // Любой код, который отличается от `200` или `400`, считаем ошибкой на сервере
    .then((response) => {
      if (response.status !== 200 || response !== 400) {
        throw new NetError('Http status error')
      } else {
        return response
      }
    })
}

/**
 * Функция устанавливает cookie "firstTime,ha?" со значением <value>
 * @param {string} value
 */
export function setCookieFirstTime(value) {
  // 2592000000 мсек = 30 суток
  document.cookie = `firstTime,ha?=${value}; expires=${new Date(
    Date.now() + 2592000000,
  ).toUTCString()}`
}

export function sendCloudNameToBot(cloudName) {
  return fetch('/rest/cloudnotfound/', {
    method: 'POST',
    body: JSON.stringify({
      cloudName,
    }),
    headers: {
      Authorization: 'Basic cXJubDp0ZXN0',
    },
  })
}
